<template>
    <div class="test-bank-wrapper">
        <div class="padding-test-bank m-0">
            <div class="clearfix">
                <div class="col-md-12">
                    <div class="row px-3">
                        <div class="col-xl-2 col-lg-3">
                            <el-input v-model="filter.title" class="input-width-test-bank" :placeholder="$t('profile.test-bank.question')"></el-input>
                            <el-select
                                clearable
                                @change="getTopics"
                                v-model="filter.subject"
                                class="input-width-test-bank margin-top-test-bank"
                                :placeholder="$t('profile.test-bank.subject')">
                                <el-option
                                    v-for="(item, key) in subjects"
                                    :key="key"
                                    :label="item"
                                    :value="key">
                                </el-option>
                            </el-select>
                            <el-select
                                clearable
                                v-model="filter.topic"
                                class="input-width-test-bank margin-top-test-bank"
                                :placeholder="$t('profile.test-bank.theme')">
                                <el-option
                                    v-for="item in topics"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </div>
                        <div class="col-xl-2 col-lg-3">
                            <el-select
                                clearable
                                v-model="filter.type"
                                class="input-width-test-bank"
                                :placeholder="$t('profile.test-bank.type')">
                                <el-option
                                    v-for="(item, key) in types"
                                    :key="key"
                                    :label="item"
                                    :value="key">
                                </el-option>
                            </el-select>
                            <el-select
                                clearable
                                v-model="filter.complexity"
                                class="input-width-test-bank margin-top-test-bank"
                                :placeholder="$t('profile.test.complexity')">
                                <el-option
                                    v-for="(item, key) in complexities"
                                    :key="key"
                                    :label="item"
                                    :value="key">
                                </el-option>
                            </el-select>
                        </div>
                        <div class="col-xl-2 col-lg-3">
                            <el-button class="btn-outline" @click="resetFilter">{{ $t('profile.test-bank.clear') }}</el-button>
                            <el-button type="primary" @click="applyFilter">{{ $t('profile.test-bank.apply') }}</el-button>
                        </div>
                        <div class="col-xl-6 col-lg-3">
                            <el-button @click="$bvModal.show('modal-add-question')" type="danger" class="add-questions-btn" icon="el-icon-plus">{{ $t('profile.test-bank.add') }}</el-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="padding-test-bank tests-table-block">
            <el-table
                v-loading="loading"
                class="tests-table"
                :data="questions"
                style="width: 100%">
                <el-table-column
                    prop="id"
                    :label="$t('profile.test-bank.id')"
                    width="66">
                </el-table-column>
                <el-table-column
                    prop="title"
                    :label="$t('profile.test-bank.question')"
                    width="350">
                </el-table-column>
                <el-table-column
                    prop="subject"
                    :label="$t('profile.test-bank.subject')"
                    width="200">
                </el-table-column>
                <el-table-column
                    prop="topic"
                    :label="$t('profile.test-bank.theme')">
                </el-table-column>
                <el-table-column
                    prop="type"
                    width="130"
                    :label="$t('profile.test-bank.type')">
                    <template slot-scope="scope">
                        {{ scope.row.type_text }}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="complexity"
                    :label="$t('profile.test.complexity')"
                    width="140">
                    <template slot-scope="scope">
                        {{ complexities[Object.keys(complexities)[scope.row.complexity-1]] }}
                    </template>
                </el-table-column>
                <el-table-column
                    fixed="right"
                    class="text-center"
                    label-class-name="text-center pl-1"
                    :label="$t('profile.test-bank.options')"
                    width="120">
                    <template slot-scope="scope">
                        <div class="actions text-center">
                            <el-tooltip class="item" effect="dark" :content="$t('profile.test-bank.edit-btn')" placement="top">
                                <el-button @click="$router.push({ name: 'question-update', params: { id: scope.row.id } })" type="default"><i class="edit-icon"></i></el-button>
                            </el-tooltip>
                            <el-tooltip class="item" effect="dark" :content="$t('profile.test-bank.delete-btn')" placement="top">
                                <el-button type="danger" @click="deleteQuestion(scope.row.id)"><i class="delete-icon"></i></el-button>
                            </el-tooltip>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <b-pagination
            v-if="total > perPage"
            v-model="currentPage"
            :total-rows="total"
            :per-page="perPage"
            last-number
            @change="getQuestions"
            first-number
            hide-goto-end-buttons
            align="center"
            next-text=">"
            prev-text="<"
        ></b-pagination>
        <div class="test-wave"></div>

        <b-modal id="modal-add-question" hide-footer hide-header centered class="modal-add-hw" size="lg">
            <i class="el-icon-close cursor-pointer float-right" @click="$bvModal.hide('modal-add-question')"></i>
            <b-tabs class="modal-add-question__tabs">
                <b-tab :title="$t('profile.test-bank.question-tab')" active class="modal-add-question__tab">
                    <div class="modal-add-hw__content">
                        <div class="modal-add-hw__select">
                            <form-group class="tests-form-group" :label="$t('profile.test.name-ru')" >
                                <el-input v-model="newQuestion.title_ru" class="w-100" :placeholder="$t('profile.test.name-ru')" ></el-input>
                            </form-group>
                            <form-group class="tests-form-group" :label="$t('profile.test.name-kz')" >
                                <el-input v-model="newQuestion.title_kz" class="w-100" :placeholder="$t('profile.test.name-kz')" ></el-input>
                            </form-group>
                            <form-group class="tests-form-group" :label="$t('profile.test-bank.subject')">
                                <el-select
                                    @change="getTopics"
                                    clearable
                                    v-model="newQuestion.subject_id"
                                    class="w-100"
                                    :placeholder="$t('profile.test-bank.subject')" >
                                    <el-option v-for="(item, key) in subjects"
                                       :key="key"
                                       :label="item"
                                       :value="key">
                                    </el-option>
                                </el-select>
                            </form-group>
                            <form-group class="tests-form-group" :label="$t('profile.test-bank.theme')">
                                <el-select clearable v-model="newQuestion.topic_id" class="w-100" :placeholder="$t('profile.test-bank.theme')">
                                    <el-option v-for="item in topics"
                                       :key="item.id"
                                       :label="item.name"
                                       :value="item.id">
                                    </el-option>
                                </el-select>
                            </form-group>
                            <form-group class="tests-form-group" :label="$t('profile.test.complexity')" >
                                <el-select clearable v-model="newQuestion.complexity" class="w-100" :placeholder="$t('profile.test.complexity')">
                                    <el-option
                                        v-for="(item, key) in complexities"
                                        :key="key"
                                        :label="item"
                                        :value="key">
                                    </el-option>
                                </el-select>
                            </form-group>
                            <form-group class="tests-form-group tests-form-group--header" :label="$t('profile.test.desc-ru')" >
                                <quill-editor
                                    v-model="newQuestion.text_ru"
                                    :options="editorOption"
                                    @ready="onEditorReady($event)"
                                />
                            </form-group>
                            <form-group class="tests-form-group tests-form-group--header" :label="$t('profile.test.desc-kz')" >
                                <quill-editor
                                    v-model="newQuestion.text_kz"
                                    :options="editorOption"
                                    @ready="onEditorReady($event)"
                                />
                            </form-group>
                            <form-group class="tests-form-group tests-form-group--header" :label="$t('profile.test.correct-comment-ru')" >
                                <el-input
                                    type="textarea"
                                    v-model="newQuestion.correct_comment_ru"
                                />
                            </form-group>
                            <form-group class="tests-form-group tests-form-group--header" :label="$t('profile.test.correct-comment-kz')" >
                                <el-input
                                    type="textarea"
                                    v-model="newQuestion.correct_comment_kz"
                                />
                            </form-group>
                            <form-group class="tests-form-group tests-form-group--header" :label="$t('profile.test.incorrect-comment-ru')" >
                                <el-input
                                    type="textarea"
                                    v-model="newQuestion.incorrect_comment_ru"
                                />
                            </form-group>
                            <form-group class="tests-form-group tests-form-group--header" :label="$t('profile.test.incorrect-comment-kz')" >
                                <el-input
                                    type="textarea"
                                    v-model="newQuestion.incorrect_comment_kz"
                                />
                            </form-group>
                        </div>
                    </div>
                    <div class="modal-add-hw__footer-btns">
                        <div class="d-flex flex-row">
                            <el-button type="primary" @click="addQuestion">{{ $t('profile.test-bank.save') }}</el-button>
                            <el-button type="danger" @click="resetAddQuestion">
                                {{ $t('profile.test-bank.reset') }}
                            </el-button>
                        </div>
                        <el-button v-show="false">{{ $t('profile.test-bank.view') }}</el-button>
                    </div>
                </b-tab>
                <b-tab :title="$t('profile.test-bank.answer-tab')" class="modal-add-question__tab">
                    <AddAnswers :question="newQuestion" :types="types" :create="true" />
                </b-tab>
            </b-tabs>
        </b-modal>
    </div>
</template>
<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import Vue from "vue";
import AddAnswers from "../../components/AddAnswers";
import { quillEditor } from "vue-quill-editor"

export default {
    components: { AddAnswers, quillEditor },
    data() {
        return {
            editorOption: {
                modules: {
                    toolbar: [['bold', 'italic', 'image']],
                    resize: {}
                }
            },
            currentPage: 1,
            total: 0,
            perPage: 1,
            checked: false,
            loading: false,
            questions: [],
            filter: {
                title: '',
                subject: '',
                topic: '',
                type: '',
                complexity: ''
            },
            letters: [
                { id: 1, name: "A" },
                { id: 2, name: "B" },
                { id: 3, name: "C" },
                { id: 4, name: "D" },
            ],
            subjects: {},
            topics: {},
            types: [],
            complexities: {
                1: 'Легкий',
                2: 'Средний',
                3: 'Сложный'
            },
            newQuestion: {
                title_ru: '',
                title_kz: '',
                subject_id: '',
                topic_id: '',
                complexity: '',
                type: '',
                answers: []
            },
            letter: '',
        }
    },
    mounted() {
        this.getQuestions(1)
    },
    methods: {
        getQuestions(page) {
            this.loading = true
            let title = this.filter.title
            let subject = this.filter.subject
            let topic = this.filter.topic
            let type = this.filter.type
            let complexity = this.filter.complexity
            this.$http.get(`${window.API_ROOT}/api/question?page=${page}&title=${title}&subject_id=${subject}&topic_id=${topic}&type=${type}&complexity=${complexity}`)
                .then((res) => {
                    this.loading = false
                    this.filterLoading = false
                    this.questions = res.body.data
                    this.subjects = res.body.subjects
                    // this.topics = res.body.topics
                    this.types = res.body.types
                    this.total = res.body.meta.total
                    this.perPage = res.body.meta.per_page
                }).catch(() => {
                    this.loading = false
                    this.filterLoading = false
                })
        },
        getTopics(subjectId) {
            this.filter.topic = '';
            this.newQuestion.topic_id = '';
            this.topics = [];
            this.$http.get(`${window.API_ROOT}/api/reference/topics/${subjectId}`)
                .then((res) => {
                    this.topics = res.body.data
                })
                .catch(() => {
                })
        },
        deleteQuestion(id) {
            this.$http.post(`${window.API_ROOT}/api/question/delete/${id}`)
                .then((res) => {
                    if (res.body.status === 'success') {
                        this.perPage = res.body.meta.per_page
                        this.currentPage = res.body.meta.current_page
                        this.resetFilter()
                        Vue.toastr({
                            message: 'Успешно!',
                            description: 'Вопрос удален',
                            type: 'success'
                        });
                    }
                })
                .catch(() => {
                })
        },
        applyFilter() {
            this.filterLoading = true
            this.currentPage = 1
            this.getQuestions(this.currentPage)
        },
        resetFilter() {
            this.filter.title = ''
            this.filter.subject = ''
            this.filter.topic = ''
            this.filter.type = ''
            this.filter.complexity = ''
            this.currentPage = 1
            this.getQuestions(this.currentPage)
        },
        addQuestion() {
            this.$http.post(`${window.API_ROOT}/api/question/create`, this.newQuestion)
                .then((res) => {
                    if (res.body.status === 'success') {
                        this.resetAddQuestion()
                        window.location.reload()
                        Vue.toastr({
                            message: 'Успешно!',
                            description: 'Вопрос сохранен',
                            type: 'success'
                        });
                    }
                })
                .catch(() => {
                })
        },
        resetAddQuestion() {
            this.$bvModal.hide('modal-add-question')
            this.newQuestion = {
                title_ru: '',
                title_kz: '',
                subject_id: '',
                topic_id: '',
                complexity: '',
                type: '',
                answers: []
            }
        },
        onEditorReady(quill) {
            quill.enable(false)
            quill.on('selection-change', function () {
                quill.enable(true)
            })
        }
    }
}
</script>
<style lang="less" scoped>
.answers-tab {
    padding: 0 10px 75px;
}
.add-questions-btn {
    float: right;
    background: #D23168;
    border-color: #D23168;
    font-family: 'Roboto', sans-serif;
}
.add-questions-btn i{
    font-weight: bold;
}
.el-button.el-button--default {
    display: block;
    width: 150px;
    color: #473F95;
    background-color: white;
    border: 1px solid #473F95;
    margin: 0;
}
.el-button.el-button--primary{
    margin: 20px 0 0;
    display: block;
    width: 150px;
    color: white;
    background-color: #473F95;
    border: 1px solid #473F95;
}
.test-wave{
  margin-top: 2rem;
  height: 90px;
  width:100%;
  background-image: url("/images/footer-wave-ktp.svg");
  background-size: cover;
  background-repeat: no-repeat;
}
.tests-table .actions .el-button{
    padding: 6px;
    border: none;
    border-radius: 50px;
    width: 40px;
    height: 40px;
    text-align: center;
    background-color: transparent;
    margin: 0;
    float: none;
    display: inline-block;
}
.tests-table .actions .el-button:focus {
    background-color: transparent;
}
.tests-table .actions .el-button.el-button--danger{
    background-color: transparent;
}
.tests-table .actions .el-button.el-button--default:hover{
    background: #ecf5ff;
}
.tests-table .actions .el-button.el-button--danger:hover{
    background: #fde3e3;
}
.tests-table .actions .edit-icon{
    background: url("/images/profile/edit.svg") no-repeat center;
    background-size: cover;
    width: 18px;
    height: 18px;
    display: block;
    margin: 0 auto;
}
.cursor-pointer {
    cursor: pointer;
}
.el-icon-close {
    background: url("/images/profile/close-lila.svg") no-repeat center;
    text-indent: -9999px;
    display: block;
    width: 24px;
    height: 24px;
}
.tests-table .actions .delete-icon{
    background: url("/images/profile/delete.svg") no-repeat center;
    background-size: cover;
    width: 16px;
    height: 16px;
    display: block;
    margin: 0 auto;
}
/deep/ .el-table::before,
/deep/ .el-table__fixed-right::before,
/deep/ .el-table__fixed::before{
    content: none;
}
/deep/ .el-table__body tr.hover-row.current-row>td,
/deep/ .el-table__body tr.hover-row.el-table__row--striped.current-row>td,
/deep/ .el-table__body tr.hover-row.el-table__row--striped>td,
/deep/ .el-table__body tr.hover-row>td{
    background-color: initial;
}
/deep/ .el-table {
    margin-top: 2rem;
    tbody {
        td {
            border: none !important;
            font-family: 'Roboto', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 19px;
            color: #473F95;
            border-radius: 4px;
            padding-left: 8px;
            padding-right: 8px;
            div{
                padding: 0;
            }
        }
    }
    th {
        border: none !important;
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 35px;
        color: #473F95;
        border-radius: 4px;
        padding-right: 5px;
        div {
            border-radius: 4px;
            padding: 8px;
            background: #E3E2F2;
        }
    }
}
.btn-red{
    margin-left: auto;
    max-height: 40px;
    cursor: pointer;
    user-select: none;
    border-radius: 5px;
    width:150px;
    color: white;
    line-height:40px;
    background-color: #D23168;
    text-align: center;
    display: flex;
    justify-content: center;
    span{
        padding-right: 10px;
        font-weight: bold;
        font-size: 27px;
        margin-top: auto;
        margin-bottom: auto;
        display: flex;
    }
}
.margin-top-test-bank{
    margin-top: 20px;
}
.test-bank-header-right-column{
    margin-right: 30px;
}
.test-bank-header-left-column{
    margin-right: 30px;
}
.btn-bg-purple{
    cursor: pointer;
    user-select: none;
    border-radius: 5px;
    width: 150px;
    color: white;
    line-height: 40px;
    background-color: #473F95;
    border: 1px solid #473F95;
    text-align: center;
    display: flex;
    justify-content: center;

    span {
        padding-right: 10px;
        font-weight: bold;
        font-size: 27px;
        margin-top: auto;
        margin-bottom: auto;
        display: flex;
    }
}
.btn-purple {
    cursor: pointer;
    user-select: none;
    border-radius: 5px;
    width: 150px;
    color: #473F95;
    line-height: 40px;
    background-color: white;
    border: 1px solid #473F95;
    text-align: center;
    display: flex;
    justify-content: center;

    span {
        padding-right: 10px;
        font-weight: bold;
        font-size: 27px;
        margin-top: auto;
        margin-bottom: auto;
        display: flex;
    }
}

.input-width-test-bank {
    width: 100%
}
.test-bank-header{
    display: flex;
}
.padding-test-bank{
    margin-right: 30px;
    margin-left: 30px;
}
.test-bank-wrapper {
    padding-top: 30px;
    margin: 0;
    background: #FFFFFF;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.04), 0 4px 16px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    max-width: 100%;
}
.test-bank-columns {
    display: flex;
}
.tests-table-block {
    overflow-x: auto;
}
.pagination {
    border: none;
    box-shadow: none;
    padding-top: 40px;
}
/deep/ .pagination .page-item .page-link {
    background: #EEEAFB;
    color: #473F95;
    border-radius: 5px;
    height: 45px;
    min-width: 58px;
    font-family: 'Roboto Condensed', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    border: none;
    margin: 0 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}
/deep/ .pagination .page-item[role=separator] .page-link {
    background: transparent;
    font-size: 28px;
    display: flex;
    align-items: flex-start;
}
/deep/ .pagination .page-item.active .page-link {
    background: #473F95;
    color: #fff;
}
/deep/ .pagination .page-item.active .page-link:focus {
    box-shadow: none;
}
/deep/ .pagination .page-item:last-child .page-link:hover,
/deep/ .pagination .page-item:first-child .page-link:hover {
    background: #EEEAFB;
    color: #473F95;
}
.test-bank-btns-bottom {
    margin-top: 60px;
    text-align: center;
}
.modal-add-hw__save {
    color: #fff;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    background: #473F95;
    border-radius: 5px;
    padding: 14px 36px;
    border: none;
    margin-right: 10px;
}
.modal-add-hw__footer-btns .el-button{
    margin: 20px 10px 0 0;
    display: block;
    width: 150px;
    color: #473F95;
    border: 1px solid #EEEAFB;
    background: #EEEAFB;
    font-family: 'Inter', sans-serif;
}
.modal-add-hw__footer-btns .el-button.el-button--primary{
    color: #ffffff;
    background-color: #473F95;
    border: 1px solid #473F95;
}
.modal-add-hw__footer-btns .el-button.el-button--danger{
    color: #ffffff;
    border: 1px solid #D23168;
    background: #D23168;
}
.modal-add-hw__view {
    color: #473F95;
    background: #EEEAFB;
    border-radius: 5px;
    padding: 14px 36px;
    border: none;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    width: 177px;
    margin-left: 10px;
}
.tests-form-group-btns {
    background: #E3E2F2;
    border-radius: 4px 4px 0 0;
    padding: 9px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.tests-form-group-btns button {
    border: none;
    background-color: transparent;
    padding: 0;
}
/deep/ .tests-form-group .signin-label {
    color: #473F95;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
}
/deep/ .tests-form-group .el-input__inner {
    border: 2px solid #E3E2F2;
}
/deep/ .tests-form-group--header .tests-form-group__textarea {
    border-radius: 0 0 4px 4px;
    box-shadow: none;
    border: 2px solid #E3E2F2;
    font-size: 14px;
}
/deep/ .tests-form-group--header .tests-form-group__textarea::placeholder,
/deep/ .tests-form-group .el-input__inner::placeholder {
    color: #473F95;
    font-size: 13px;
    line-height: 15px;
}
.el-checkbox {
    color: #473F95;
}
.el-checkbox__input.is-checked+.el-checkbox__label,
/deep/ .el-checkbox__input.is-checked+.el-checkbox__label {
    color: #473F95;
}
/deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
/deep/ .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #473F95;
    border-color: #473F95;
}
.modal-add-hw__add {
    color: #FFFFFF;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    font-family: 'Inter', sans-serif;
    padding: 14px 24px;
    background: #473F95;
    border-radius: 5px;
    display: block;
    border: none;
    margin: 24px 0;
}
.modal-add-hw__footer-btns--answer .modal-add-hw__save {
    background: #EEEAFB;
    color: #473F95;
}
/deep/ .el-select .el-input.is-focus .el-input__inner,
/deep/ .el-input.is-active .el-input__inner, /deep/  .el-input__inner:focus {
    border-color: #473F95;
}
.tests-form-group__delete {
    color: #D23168;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    border: none;
    background-color: transparent;
    padding: 0;
}
@media (max-width: 1400px) {
    .test-bank-wrapper {
        width: 100%;
    }
    .test-bank-columns {
        flex-direction: column;
    }
    .btn-red {
        margin-left: 20px;
    }
    .test-bank-header-left-column {
        margin-right: 0;
    }
    .test-bank-header-right-column {
        margin-top: 20px;
        margin-right: 0;
    }
    .test-bank-btn-column {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
    }
    .btn-purple {
        margin-top: 20px;
        width: 50%;
        margin-right: 12px;
    }
    .btn-bg-purple {
        width: 50%;
    }
}
.modal-add-hw__footer-btns {
    margin-top: 8px;
    margin-bottom: 126px;
    display: flex;flex-direction: row;
}

/deep/ #modal-add-question .modal-body {
    background-image: url("/images/vave-bg-modal.svg");
    background-position: bottom;
    background-size: contain;
    background-repeat: no-repeat;
}
/deep/ .modal-add-question__tabs .nav-tabs {
    border-bottom: none;
}
/deep/ .modal-add-question__tabs .nav-tabs .nav-link {
    border: none;
    color: #473F95;
    font-size: 14px;
    line-height: 16px;
    padding: 10px;
}
/deep/ .modal-add-question__tabs .tab-content {
    padding-top: 20px;
}
/deep/ .modal-add-question__tabs .nav-tabs .nav-link.active,
/deep/ .modal-add-question__tabs .nav-tabs .nav-item.show .nav-link {
    border-bottom: 3px solid #473F95;
}
@media (max-width: 991px) {
    .test-bank-wrapper {
        box-shadow: none;
        padding-top: 0;
    }
    .padding-test-bank {
        padding: 0;
    }
    /deep/ .page-item.active .page-link {
        z-index: 0;
    }
    /deep/ .modal-add-question__tabs .nav-tabs .nav-link {
        border-radius: 10px;
        background: #FDFEFF;
        border: 1px solid #473F95;
        box-sizing: border-box;
        backdrop-filter: blur(20px);
        width: 152px;
        text-align: center;
    }
    /deep/ .modal-add-question__tabs .nav-tabs .nav-link.active,
    /deep/ .modal-add-question__tabs .nav-tabs .nav-item.show .nav-link {
        background: #473F95;
        border-radius: 10px;
        border: 1px solid #473F95;
        box-sizing: border-box;
        backdrop-filter: blur(20px);
        color: #fff;
    }
    /deep/ .nav-tabs {
        justify-content: center;
    }
    /deep/ .nav-tabs .nav-item:first-child {
        margin-right: 10px;
    }
    .modal-add-hw__footer-btns {
        flex-direction: column;
    }
    /deep/ #modal-add-question .modal-body {
        background-image: none;
    }
    .modal-add-hw__footer-btns {
        margin-bottom: 24px;
    }
    /deep/ #modal-add-question .modal-add-question__tabs {
        margin-top: 24px;
    }
}
@media (max-width: 767px) {
    .test-bank-header {
        flex-direction: column;
    }
    .input-width-test-bank {
        width: 100%;
    }
    .btn-red {
        width: 100%;
        margin-left: 0;
        margin-top: 32px;
    }
    .pagination {
        margin-bottom: 0;
    }
    /deep/ .pagination .page-item .page-link {
        font-size: 14px;
        line-height: 16px;
        min-width: 38px;
    }
    .test-wave {
        display: none;
    }
    .tests-table {
        margin-top: 16px;
    }
    .modal-add-hw__save,
    .modal-add-hw__cancel,
    .modal-add-hw__view {
        width: 100%;
    }
    .modal-add-hw__view {
        margin-top: 12px;
        margin-left: 0;
    }
    .test-bank-columns {
        width: 100%;
    }
    .add-questions-btn {
        float: none;
        width: 100%;
        margin-top: 32px;
    }
    .test-bank-btn-column {
        flex-direction: row;
        justify-content: center;
    }
    .test-bank-btn-column > .el-button.el-button--primary {
        margin-top: 0;
        margin-left: 12px;
    }
    .test-bank-header {
        margin: 0;
    }
}

</style>